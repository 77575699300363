"use client";
import classNames from "classnames";
import { useState } from "react";
import { BsFileArrowUp } from "../../../react-icons/bs";

type Props = {
  onDrop: (files: any) => void;
  id?: string;
  children: any;
};


export function DropZoneImg({ id = "", onDrop, children }: Props) {
  const [isDragin, setIsDragin] = useState<boolean>(false);

  const onChange = (previews: []) => {
    onDrop(previews);
  };


  const onDragEnter = (e: any) => {
    e.preventDefault();
    setIsDragin(true);
  };

  const onDragLeave = (e: any) => {
    e.preventDefault();
    setIsDragin(false);
  };

  const onDragOver = (e: any) => {
    e.preventDefault();
  };

  const onDragDrop = (e: any) => {
    e.preventDefault();
    onChange(e.dataTransfer.files);
    setIsDragin(false);
  };

  return (
    <div
      id={id}
      className={classNames("drop-zone-container o-hidden img")}
      onDragEnter={onDragEnter}
      onDragExit={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDragDrop}
    >
      <div className={classNames("cache", isDragin === true ? "visible" : "hidden")}>
        <BsFileArrowUp />
        <p>Ajouter le(s) fichier(s) en le relachant</p>
      </div>
      {children}
    </div>
  );
}
