"use client";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { IconButton } from "ui/components/buttons";
import { bitToMo } from "../../../../utilities/tools/converter";
import { IoDocumentOutline, IoDocumentTextOutline } from "../../../react-icons/io5";

type Props = {
  filesAccepted?: string;
  legend?: string;
  files?: any;
  onSubmit: (param: Array<any> | null) => void;
};

export function DropZoneText({ onSubmit, legend = "Déposer votre fichier FEC", files, filesAccepted = "text/plain" }: Props) {
  const [isDragin, setIsDragin] = useState<boolean>(false);
  const [preview, setPreview] = useState<any>(files);
  const dropzoneRef = useRef(null);
  const inputRef: any = useRef(null);

  const onDragEnter = (e: any) => {
    e.preventDefault();
    setIsDragin(true);
  };
  const onDragLeave = (e: any) => {
    e.preventDefault();
    setIsDragin(false);
  };
  const onDragOver = (e: any) => {
    e.preventDefault();
    setIsDragin(true);
  };
  const onDragDrop = (e: any) => {
    e.preventDefault();
    onChange(e.dataTransfer.files);
    setIsDragin(false);
  };

  const onChange = (previews: []) => {
    setPreview(Array.from([...previews]));

    onSubmit(previews);
  };

  const onClick = () => {
    inputRef?.current?.click();
  };

  useEffect(() => {
    return () => {
      onSubmit(null);
    };
  }, []);

  return (
    <div data-test="drop-text" className={classNames("dropzone empty clickable tile mt-1 mb-1 tile br-3 pb-1", preview === null && "clickable")}>
      <div className={classNames("drop-zone-container")} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDragDrop} onDragOver={onDragOver}>
        <input ref={inputRef} hidden type="file" accept={filesAccepted} onChange={(e: any) => onChange(e?.target?.files)} />
        {(preview === null || !preview?.[0]) && (
          <div className={classNames("drop-zone tile transparent", isDragin === true && "dragin")} ref={dropzoneRef} onClick={onClick}>
            <div className="icon-inactive mt-1">
              <IoDocumentTextOutline size={45} color={"var(--color-text)"} />
            </div>
            <strong className="text-center">{legend}</strong>
            <p className="text-center">Faites un glisser-déposer ou cliquer sur le bloc pour uploader le fichier</p>
            <em className="text-center subtitle mb-1 mt-0">Taille maximale 128 Mo</em>
          </div>
        )}

        {!!preview && preview[0]
          && <div className={classNames("fec", "drop-zone", isDragin === true && "dragin")}>
            <div className="icon">
              <IoDocumentOutline size={35} className="p-2" />
            </div>
            <div className="file">
              <div className="name">{preview[0].name}</div>
              <div className="size">{bitToMo(preview[0].size)} Mo</div>
            </div>

            <IconButton size={25} icon={"edit"} cb={onClick} className="ml-auto mr-0 mb-0 mt-0" type="dark" tooltip="Modifier le fichier" />
          </div>}
      </div>
    </div>
  );
}
